<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1>Error</h1>
          <b-alert show variant="danger">
            <p>It looks like there is an issue with your profile</p>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ConsoleError",
};
</script>

<style></style>
